import React, { useRef, useState, useEffect } from "react";
import useEventListener from "../../../hooks/useEventListener";
import * as styles from "./Timeline.module.scss";

const Year = ({ year }) => {
	const item = useRef(null);
	const [itemHeight, setItemHeight] = useState(null);

	const getItemHeight = () => {
		if (!item?.current) return;
		setItemHeight(item.current.clientHeight);
	};

	useEffect(getItemHeight, []);
	useEventListener("resize", getItemHeight);

	return (
		<div
			ref={item}
			className={styles.year}
			style={{ "--itemHeight": `${itemHeight}px` }}
		>
			<h4>{year.year.text}</h4>
			<div dangerouslySetInnerHTML={{ __html: year.year_content.html }} />
		</div>
	);
};

export default Year;
