import React from "react";
import { slugify } from "../../../utils/helpers";
import Year from "./Year";
import * as styles from "./Timeline.module.scss";

const Timeline = ({ title, content }) => (
	<div id={slugify(title)} className={styles.timeline}>
		<h3 className="block-title">{title}</h3>

		{content.map((year, i) => (
			<Year key={i} {...{ year }} />
		))}
	</div>
);

export default Timeline;
