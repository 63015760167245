import React from "react";
import { graphql } from "gatsby";
import { className, slugify } from "../utils/helpers";
import Seo from "../components/shared/Seo";
import Timeline from "../components/shared/Timeline";
import Accordion from "../components/shared/Accordion";
import StickyMenu from "../components/shared/StickyMenu";
import * as styles from "./styles/EducationPage.module.scss";

const EducationPage = ({ data }) => {
	const { data: pageData } = data.allPrismicEducation.edges[0].node;

	return (
		<>
			<Seo
				title={pageData.title.text}
				description={pageData.description.text}
			/>

			<div
				id={slugify(pageData.section_title.text)}
				{...className(styles.educationPage, "page-content")}
			>
				<h2 className="section-title">{pageData.section_title.text}</h2>

				<div
					dangerouslySetInnerHTML={{
						__html: pageData.section_content.html,
					}}
				/>

				<Timeline
					title={pageData.timeline_title.text}
					content={pageData.timeline_content}
				/>

				<Accordion
					title={pageData.education_title.text}
					content={pageData.education_accordion}
				/>

				<StickyMenu tags="h2, h3" />
			</div>
		</>
	);
};

export const query = graphql`
	{
		allPrismicEducation {
			edges {
				node {
					data {
						title {
							text
						}
						description {
							text
						}
						section_title {
							text
						}
						section_content {
							html
						}
						timeline_title {
							text
						}
						timeline_content {
							year {
								text
							}
							year_content {
								html
							}
						}
						education_title {
							text
						}
						education_accordion {
							education_section_title {
								text
							}
							education_section_content {
								html
							}
						}
					}
				}
			}
		}
	}
`;

export default EducationPage;
