import React, { useState } from "react";
import { plusIcon, minusIcon } from "../../../utils/icons";
import * as styles from "./Accordion.module.scss";

const Item = ({ item }) => {
	const [itemCollapsed, setItemCollapsed] = useState(true);

	return (
		<div className={styles.item}>
			<button onClick={() => setItemCollapsed(!itemCollapsed)}>
				<h4>{item.education_section_title.text}</h4>

				<span>
					{itemCollapsed ? plusIcon.regular : minusIcon.regular}
				</span>
			</button>

			{!itemCollapsed && (
				<div dangerouslySetInnerHTML={{ __html: item.education_section_content.html }} />
			)}
		</div>
	);
};

export default Item;
