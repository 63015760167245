import React from "react";
import { slugify } from "../../../utils/helpers";
import Item from "./Item";
import * as styles from "./Accordion.module.scss";

const Accordion = ({ title, content }) => (
	<div id={slugify(title)} className={styles.accordion}>
		<h3 className="block-title">{title}</h3>

		{content.map((item, i) => (
			<Item key={i} {...{ item }} />
		))}
	</div>
);

export default Accordion;
